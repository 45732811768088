import {EmailIcon, FacebookIcon, LinkedinIcon} from "react-share";
import React from "react";

export default function Links(props) {
    return (
        <div className="LinksText">
            <div>
                <h1 className="LinksTitle">Resume and Socials </h1>
                <a href={props.pdf} target="_blank" rel="noreferrer">
                    <img src={props.pdf_icon} alt="pdf_icon"/>
                </a>
                <a href="https://www.facebook.com/alex.rataezyk.5/"> <FacebookIcon size={40} round/></a>
                <a href="https://www.linkedin.com/in/alex-rataezyk-076299160/"> <LinkedinIcon size={40} round/></a>
                <a href="mailto:acrataezyk@gmail.com"> <EmailIcon size={40} round/></a>
            </div>
        </div>
    )
}