import './App.css';
import Header from './components/Header'
import AboutMe from "./components/AboutMe";
import Technologies from "./components/Technologies"
import Links from './components/Links'
import './styles/Header.css'
import './styles/AboutMe.css'
import './styles/Technologies.css'
import './styles/Links.css'
import Alex from './images/Alex_Professional_Photo.jpg'
import pdfIcon from './images/pdf_icon.png'
import pdf from './documents/Alexs_Resume.pdf'
import Blep from './images/Blep.jpg'

function App() {
  return (
    <div className="App font-face-oswald">
        <Header title={"Alex Rataezyk"} subTitle={"Full Stack Software Engineer"}/>
        <Links  pdf={pdf} pdf_icon={pdfIcon} />
        <div className="Body-Content">
            {/*<div className="Column">*/}
                <AboutMe Alex={Alex} />
            {/*</div>
            <div className="Column">*/}
                <Technologies  blep={Blep}/>
            {/*</div>*/}
        </div>

    </div>
  );
}

export default App;
