

export default function Header(props) {

    return (
        <div>
            <div className={"headerTitle"}>
                {props.title}
            </div>
            <div className={"headerSubtitle"}>
                {props.subTitle}
            </div>

        </div>
    );
}