import React, {useState} from "react";


export default function Technologies(props) {
    const [isBlepShown, setBlepShown] = useState(false);
    const showBlep = (value) => {
        setBlepShown(value);
    }
    const buildBlep = () => {
        if (isBlepShown) {
            return (
                <div className={"BlepText"}>
                    <h1>
                        Blep!
                    </h1>
                    <img className="BlepImage"
                         src={props.blep}
                         alt="Alex"
                    />
                </div>
            );
        }
        else {
            return (
                <div className={"bottomText"}>
                </div>
            );
        }
    }
    return(
        <div>
            <h1 className="TitleText">
                I have experience using:
            </h1>
            <h1 className="KnownStuff">
                C#, Javascript, React, Jquery, SQL, HTML, CSS, <br />
                LINQ, SQL Server Management Studio, MySQL, <br />
                The Entity Framework, Visual Studio, WebStorm, <br />
                Git, Azure, and AWS
            </h1>
            <div className="ButtonPosition">
                <button className="ButtonStyle" onClick={() => showBlep(!isBlepShown)}>Click me?</button>
            </div>

            {buildBlep()}


        </div>
    )
}