import React from 'react'


export default function AboutMe(props){
    return(

        <div className="">
            <h1 className={"AboutMeText"}>
                Hello there! I am Alex, a software engineer by trade. <br />
                I am passionate about learning, as I hope to <br />
                expand my knowledge to encompass newer ideas and technologies <br />
                in a rapidly changing environment.
            </h1>
            <img className="Alex_Image"
                src={props.Alex}
                alt="Alex"
            />
        </div>
    )
}